<template>
    <div class="projects">
        <Projects />
    </div>
</template>


<script>
import Projects from "@/components/Projects.vue";

export default {
    components: {
        Projects,
    }
}
</script>
